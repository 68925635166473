import React from "react";
import "./skills.sass";


export default function Skills() {
  return (
    <React.Fragment>
     
        <div className="Skills" id="skills">
      
      <div className="title">
      <h1 className="titletext">Skills</h1>
      </div>
      <div className="skillset">
        <div className="subskill">
          <h3>Programming</h3>
          <h4>
            <ul>
              <span className="badge bg-secondary skillspace">Java</span>
              <span className="badge bg-secondary skillspace">SQL</span>
              <span className="badge bg-secondary skillspace">HTML</span>
              <span className="badge bg-secondary skillspace">CSS</span>
              <span className="badge bg-secondary skillspace">Sass</span>
              <span className="badge bg-secondary skillspace">JavaScript</span>
              <span className="badge bg-secondary skillspace">TypeScript</span>
              <span className="badge bg-secondary skillspace">Apex</span>
              <span className="badge bg-secondary skillspace">Python</span>
              <span className="badge bg-secondary skillspace">C++</span>
              <span className="badge bg-secondary skillspace">C</span>
              <span className="badge bg-secondary skillspace">Bash</span>
              <span className="badge bg-secondary skillspace">ksh</span>
              <span className="badge bg-secondary skillspace">R</span>
            </ul>
          </h4>
        </div>

        <div className="subskill">
          <h3>Frameworks</h3>
          <h4>
            <ul>
              <span className="badge bg-secondary skillspace">
                Lightning Web Components
              </span>
              <span className="badge bg-secondary skillspace">Angular</span>
              <span className="badge bg-secondary skillspace">React</span>
            </ul>
          </h4>
        </div>
        <div className="subskill">
          <h3>Data</h3>
          <h4>
            <ul>
              <span className="badge bg-secondary skillspace">DataStage</span>
              <span className="badge bg-secondary skillspace">Control-M</span>
              <span className="badge bg-secondary skillspace">Snowflake</span>
              <span className="badge bg-secondary skillspace">SF Data Loader</span>
              <span className="badge bg-secondary skillspace">Netezza</span>
              <span className="badge bg-secondary skillspace">Excel</span>
              <span className="badge bg-secondary skillspace">Kafka</span>
              <span className="badge bg-secondary skillspace">Tableau</span>
              <span className="badge bg-secondary skillspace">dbt</span>
            </ul>
          </h4>
        </div>
        <div className="subskill">
          <h3>Testing</h3>
          <h4>
            <ul>
              <span className="badge bg-secondary skillspace">qTest</span>
              <span className="badge bg-secondary skillspace">Unit Tests</span>
              <span className="badge bg-secondary skillspace">System Tests</span>
              <span className="badge bg-secondary skillspace">
                Functional Tests
              </span>
              <span className="badge bg-secondary skillspace">
                Integration Tests
              </span>
            </ul>
          </h4>
        </div>
        <div className="subskill">
          <h3>DevOps</h3>
          <h4>
            <ul>
              <span className="badge bg-secondary skillspace">
                UrbanCode Deploy
              </span>
              <span className="badge bg-secondary skillspace">Copado</span>
              <span className="badge bg-secondary skillspace">GitLab CI/CD</span>
              <span className="badge bg-secondary skillspace">Docker</span>
              <span className="badge bg-secondary skillspace">Openshift</span>
            </ul>
          </h4>
        </div>
        <div className="subskill">
          <h3>Certifications</h3>
          <h4>
            <ul>
              <span className="badge bg-secondary skillspace">
                Salesforce Certified Administrator
              </span>
            </ul>
          </h4>
        </div>
      </div>
    </div>
    </React.Fragment>
    
  );
}
