import React from "react";
import "./header.sass";

export default function Header() {
  return (
    // <div className="header">
    <nav className="header navbar-expand-md">
      {/* <ul className="navLinks"> */}
      <div className="navleft" id="navleft">
        <a className="logo" href="#intro">
          psoisson.dev
        </a>
      </div>
      <div className="navright" id="navright">
        <button
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarLinks"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarLinks">
          {/* <div className="right"> */}
          <ul className="navbar-nav ">
            <li class="nav-item active">
              <a class="nav-link" id="navitem" href="#intro">
                Intro
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" id="navitem" href="#experience">
                Experience
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" id="navitem" href="#portfolio">
                Projects
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" id="navitem" href="#skills">
                Skills
              </a>
            </li>
            <li class="nav-item active">
              <a
                class="nav-link"
                id="navitem"
                href="https://drive.google.com/file/d/1PvwUoyCxIIKO3jD-8DUpjbf-gwSdoloe/view?usp=share_link"
                target="_blank"
              >
                Resume
              </a>
            </li>
          </ul>
        </div>
        {/* </div> */}
        {/* </ul> */}
      </div>
    </nav>
    // </div>
  );
}
